import discountExceptions from "./discountExceptions"
import skusDiscounts from "./discountPercentage"

const calculateDiscount = (discountPromoOn, selectedVariant, discount, price, storeLocale, hideBundlePricing) => {
  const isItemExcluded = discountExceptions[storeLocale].includes(selectedVariant?.SKU)

  if (discountPromoOn && storeLocale === 'en-GB') {
    return {
      isItemExcluded: false,
      amount: price * 20 / 100,
      percentage: 20
    }
  }

  if (!discountPromoOn || isItemExcluded || !selectedVariant?.oosData?.on_site || !selectedVariant?.oosData?.avaliable) {
    return {
      isItemExcluded: isItemExcluded,
      amount: discount,
      percentage: 0,
      orginalDiscount: discount
    }
  }


  const discountPercentage = skusDiscounts[storeLocale][selectedVariant?.SKU]?.amount
  return {  
    isItemExcluded: isItemExcluded,
    amount: discountPromoOn && discountPercentage ? price * discountPercentage / 100 : 0,
    percentage: discountPercentage
  }
}

export default calculateDiscount