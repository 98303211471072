import styled from '@emotion/styled';
import React, { useEffect, useRef, memo } from 'react';
import { useShopStoreIncontext } from '../../context/shopContext';
import fbq from '../../helpers/fbq';
import twq from '../../helpers/twq';
import Cart from '../Cartv2';
import { Slice } from 'gatsby';
import EmptyLayout from './empty';
import Div100vh from 'react-div-100vh';
import { persLookup } from '../../templates/product-page';
import LocationSelector from '../LocationSelector';
import PersonalisationModal from '../Personalisation'
import { suggestedLocales } from '../LocationSelector';
import axios from 'axios'

export const MenuRight = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  background: white;
  transition: all 0.9s cubic-bezier(0.25, 1, 0.5, 1);
  z-index: 9999999999999999999;
  box-shadow: -3px 0 5px 0 #55555588;
  overflow: scroll;

  right: 0;
  width: 100vw;
  transform: translateX(${(props) => (props.isOpen ? '0' : 'calc(100vw + 5px)')});

  @media (min-width: 1025px) {
    transform: translateX(${(props) => (props.isOpen ? '0' : '555px')});
    width: 550px;
  }
`;

export const MenuLeft = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  background: white;
  transition: all 0.9s cubic-bezier(0.25, 1, 0.5, 1);
  z-index: 9999999;
  box-shadow: -3px 0 5px 0 #55555588;
  overflow: scroll;

  left: 0;
  width: 100vw;
  transform: translateX(${(props) => (props.isOpen ? '0' : 'calc(-100vw - 5px)')});

  @media (min-width: 1025px) {
    transform: translateX(${(props) => (props.isOpen ? '0' : '-555px')});
    width: 550px;
  }
`;

const OpaqueCover = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: #222222;
  transition: all 0.9s cubic-bezier(0.25, 1, 0.5, 1);
  z-index: 9999998;
  opacity: ${(props) => (props.isOpen ? '0.7' : '0')};
`;

const SideNav = memo(({ isOpen, close, children, side }) => {
  const MenuSlider = side === 'left' ? MenuLeft : MenuRight;
  return (
    <>
      {isOpen && <OpaqueCover onClick={close} isOpen={isOpen} />}
      <MenuSlider isOpen={isOpen}>
        <Div100vh style={{ overflow: 'hidden' }}>{children}</Div100vh>
      </MenuSlider>
    </>
  );
});

const ChildrenContainer = memo(({ children }) => (
  <div style={{ paddingTop: 0, background: 'white', minHeight: '60vh' }}>{children}</div>
));

const Layout = ({ children, pageContext, location }) => {
  const params = new URLSearchParams(location.search)
  const cartId = params.get('cartId')
  const {
    cartOpen,
    setCartOpen,
    menuOpen,
    setMenuOpen,
    pauseScroll,
    personalisingIndex,
    setPersonalisingIndex,
    storeLocale,
    cart,
    locationSelectorOpen,
    setLocationSelectorOpen,
    ipCountryCode,
    initCart,
    addPersonalisationToItem,
    setIpCountryCode,
  } = useShopStoreIncontext((state) => {
    return {
      cartOpen: state.cartOpen,
      setCartOpen: state.actions.setCartOpen,
      menuOpen: state.menuOpen,
      setMenuOpen: state.actions.setMenuOpen,
      pauseScroll: state.pauseScroll,
      personalisingIndex: state.personalisingIndex,
      setPersonalisingIndex: state.actions.setPersonalisingIndex,
      storeLocale: state.storeLocale,
      cart: state.cart,
      locationSelectorOpen: state.locationSelectorOpen,
      setLocationSelectorOpen: state.actions.setLocationSelectorOpen,
      ipCountryCode: state.ipCountryCode,
      initCart: state.actions.initCart,
      addPersonalisationToItem: state.actions.addPersonalisationToItem,
      setIpCountryCode: state.actions.setIpCountryCode,
    }
  })

  useEffect(() => {
    if (!cartId) {
      initCart()
      return
    }
    const fetchCart = async () => {
      const result = await axios.get(
        `${process.env.GATSBY_SERVERLESS_API_URL}/dev/json/get`,
        {
          params: {
            id: cartId,
          },
        }
      )

      if (!result.status === 200) {
        return
      }
      const cart = result.data?.data
      initCart(cart)
      setTimeout(() => {
        setCartOpen(true)
      }, 2000)
    }

    fetchCart()
  }, [cartId, initCart])

  useEffect(() => {
    const handleFetchIp = (e) => {
      const ipLoc = e.detail
      const countryCode = ipLoc.response.countryCode
      setIpCountryCode(countryCode)
      const hasInteracted =
        localStorage.getItem('locationSelectorInteracted') === 'true'

      if (
        !suggestedLocales[storeLocale].includes(countryCode) &&
        !hasInteracted
      ) {
        setLocationSelectorOpen(true)
        localStorage.setItem('locationSelectorInteracted', 'true')
      }
    }
    window.addEventListener('fetch-ip', handleFetchIp)

    return () => {
      window.removeEventListener('fetch-ip', handleFetchIp)
    }
  }, [setIpCountryCode, setLocationSelectorOpen, storeLocale])

  const pageViewedRef = useRef();

  const personalising = personalisingIndex !== undefined;

  useEffect(() => {
    if (cartOpen || menuOpen || pauseScroll || personalising || locationSelectorOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [cartOpen, menuOpen, pauseScroll, personalising, locationSelectorOpen]);

  useEffect(() => {
    const fbids = localStorage?.getItem('fbid');
    setMenuOpen(false);
    if (location.href !== pageViewedRef.current) {
      fbq('PageView');
      twq('tw-oe3re-oe3st');
      axon("track", "page_view");
      console.log(`page viewed ${pageViewedRef.current}`);
      pageViewedRef.current = location.href;
    }

    if (['travel-bags', 'luggage', 'travel-accessories'].includes(location.pathname.replace('/au/', '').replace('/us/', '').split('/')[0])) {
      twq('tw-oe3re-oe3st');
      if (fbids != null) {
        if (typeof window !== 'undefined') {
          setTimeout(() => {
            const localeToCurrency = {
              'en-AU': 'AUD',
              'en': 'USD',
              'en-NZ': 'NZD',
              'en-CA': 'CAD',
              'en-GB': 'GBP',
            }
            fbq('ViewContent', {
              content_type: 'product',
              content_ids: [localStorage?.getItem('fbid')],
              currency: localeToCurrency[storeLocale],
            })
          }, 1500)
        }
      }
    }
  }, [location, setMenuOpen]);

  if (pageContext.layout === 'empty') {
    return <EmptyLayout>{children}</EmptyLayout>;
  }

  const handleSavePersonalisation = (data) => {
    addPersonalisationToItem(personalisingIndex, data)
  }

  const personalisingProduct = personalisingIndex !== undefined ? cart[personalisingIndex] : {};
  return (
    <div className="flex min-h-screen flex-col justify-between bg-white text-neutral-900">
      <div style={{ display: personalising ? 'block' : 'none', height: '100vh' }}>
        {personalising && (
          <div style={{ position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, zIndex: 9999999999 }}>
            <PersonalisationModal
              handleSave={handleSavePersonalisation}
              handleClose={() => setPersonalisingIndex()}
              initialValues={{}}
              selectedCaseColor={persLookup[personalisingProduct?.globalProduct[storeLocale]?.sku]?.color_id}
              selectedCase={persLookup[personalisingProduct?.globalProduct[storeLocale]?.sku]?.product}
              timeframe={storeLocale === 'en-GB' ? '7' : storeLocale === 'en' ? '5-7' : '3-7'}
            />
          </div>
        )}
      </div>
      <LocationSelector
        locationSelectorOpen={locationSelectorOpen}
        setLocationSelectorOpen={setLocationSelectorOpen}
        currentCountryCode={ipCountryCode}
        storeLocale={storeLocale}
      />
      <SideNav isOpen={cartOpen} close={() => setCartOpen(false)}>
        <Cart pageContext={pageContext} location={location} />
      </SideNav>
      <Slice alias="navigation" pathname={location.pathname} navStyle={pageContext.navStyle}/>
      <ChildrenContainer>{children}</ChildrenContainer>
      <Slice alias="footer" />
    </div>
  );
};

export default memo(Layout);
