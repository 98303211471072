import styled from '@emotion/styled'
import React from 'react'
import ButtonCTA from '../Button/button_cta'

const Body = styled.div`
  color: #212121;
  font-family: Objectivity;
  font-size: 16px;
  line-height: 23px;
  margin: 16px 13px;
  width: calc(100% - 26px);

  & p {
	  margin: 16px 0;
	}

  & a {
    word-wrap: break-word; /* Allow long links to break into multiple lines */
  }

  & span {
    word-wrap: break-word; /* Allow long links to break into multiple lines */
  }
`
const TextContaner = styled.div`
  width: calc(100% - 26px);
  line-height: 1.39;
  max-width: 800px;
  h1 {
    font-size: 40px;
    line-height: 1.39
  }
  h2 {
    font-size: 32px;
    line-height: 1.39
  }
  h3 {
    font-size: 26px;
    line-height: 1.39
  }
  h4 {
    font-size: 20.8px;
    line-height: 1.39
  }
  h5 {
    font-size: 16px;
    line-height: 1.39
  }

  ol {
    list-style-type: decimal;
    margin: 0px;
    padding: 0px 0px 0px 24px;
  }
  ul {
    list-style-type: initial;
    margin: 0px;
    padding: 0px 0px 0px 24px;
  }
`
const StaticContainer = styled.section`
  @media (max-width: 1025px) {
    margin-top: 10px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
`

const StaticBody = ({ data }) => {
  return (
    <StaticContainer>
      <TextContaner>
        <Body dangerouslySetInnerHTML={{ __html: data.Body }} />
      </TextContaner>
      {data?.Button && (
        <ButtonCTA style={{ padding: '0 48px', margin: '12px' }} data={data.Button} onClick={() => { trackSelectPromotion({ creative_name: data.Heading, promotion_id: data.id }) }}>
          {' '}
          {data.Button.ButtonCopy}{' '}
        </ButtonCTA>
      )}
    </StaticContainer>
  )
}

export default StaticBody
