import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import React, { useEffect } from 'react';

const ContentBlockData = styled.div`
  display: block;
  margin: 0 auto;
  align-content: center;
  color: ${({ textColor }) => (textColor ? textColor : 'black')};
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'transparent')};
  padding: 32px;
  padding-bottom: ${({ noTitle }) => (noTitle ? '0' : '32px')};

  @media (max-width: 768px) {
    padding: 32px 0;
      padding-bottom: ${({ noTitle }) => (noTitle ? '0' : '32px')};
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  font-size: 74px;
  line-height: 1.39;
  padding: 16px;
  padding-bottom: 24px;
  margin: 0 auto;
  text-align: center;
  font-weight: ${({ fontWeight }) => (fontWeight === 'bold' ? '800' : '500')};
  font-family: ${({ fontWeight }) => (fontWeight === null ? 'PPEditorialNew' : '')};
  font-style: ${({ fontWeight }) => (fontWeight === null ? 'italic' : '')};

  ${({containsTennis}) => containsTennis ? "font-family: 'Roslindale'; font-style: normal;" : ''}

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 1.39;
  }
`;

const SubTitle = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
  margin: 0 auto;
  letter-spacing: 0.39px;
  max-width: 800px;
  text-align: center;
  padding: 16px 0;
  font-family: ${({ staticTitle }) => (!staticTitle ? '' : 'monospace')};

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 0 32px;
  }
`;
const RestOfSentence = styled.span`
`;

const Body = styled.div`
  font-size: 16px;
  padding: 32px;
  margin: 0 auto;
  max-width: 1000px;
  align-items: center;
  text-align: center;
  line-height: 2;
  padding-top: 0;

  & a {
    text-decoration: underline;
    font-size: 36px;
    font-weight: 800;
    color: inherit;
    transition: color 0.3s ease;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  & a:hover {
    color: ${({ backgroundColor }) => (!backgroundColor ? 'lightgreen' : 'lightgrey')};
  }

  figure {
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 36px;
    padding-top: 0;
  }
`;

const caret = keyframes`
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

const text1 = keyframes`
  0%, 50%, 100% {
    width: 0;
  }
  10%, 40% {
    width: 17em;
  }
`;

const text2 = keyframes`
  0%, 50%, 100% {
    width: 0;
  }
  60%, 90% {
    width: 21.2em;
  }
`;

const AnimatedText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  animation-duration: 20s;
  animation-timing-function: steps(25, end);
  animation-iteration-count: infinite;

  @media (min-width: 769px) {
    max-width: 425px;
  }
  @media (min-width: 1025px) {
    max-width: 420px;
  }
  @media (max-width: 768px) {
    max-width: 180px;
  }

  &::after {
    position: absolute;
    right: 0;
    animation: ${caret} infinite;
    animation-duration: 15s;
    animation-timing-function: steps(1, end);
  }

  &.text_1 {
    animation-name: ${text1};
  }

  &.text_2 {
    animation-name: ${text2};
  }
`;

function extractFirstThreeWords(sentence) {
  let words = sentence?.split(" ");
  let firstThreeWords = words?.slice(0, 3).join(" ");
  let restOfSentence = words?.slice(3).join(" ");
  return { firstThreeWords, restOfSentence };
}

const ContentBlockTitle = ({ data }) => {
  let mainTitle = extractFirstThreeWords(data?.Title);
  let swapTitle = extractFirstThreeWords(data?.SwapTitle);

  return (
    <ContentBlockData textColor={data?.PrimaryTextColor} backgroundColor={data?.BackgroundColor} noTitle={data?.Title === null}>
      {
        data?.Title && (
          <Title fontWeight={data?.TitleStyle} titleSize={data?.TitleSize} containsTennis={data?.Title?.includes("tennis")}>
            {
              data?.SwapTitle ? (
                <>
                  <AnimatedText className="text_1">{mainTitle.firstThreeWords}</AnimatedText>
                  <AnimatedText className="text_2">{swapTitle.firstThreeWords}</AnimatedText>
                  <RestOfSentence>{mainTitle?.restOfSentence}</RestOfSentence>
                </>
              ) : (
                data?.Title
              )
            }
          </Title>
        )
      }
      {
        data?.SubTitle ? (
          <SubTitle staticTitle={data?.SwapTitle}>{data?.SubTitle}</SubTitle>
        ) : (
          <></>
        )
      }
      {
        data?.Body && (
          <Body backgroundColor={data?.BackgroundColor} dangerouslySetInnerHTML={{ __html: data?.Body }} />
        )
      }
      {
        data?.tolstoyWidgetOn && (
          <div
            style={{height: 515}}
            className="tolstoy-carousel"
            data-publish-id="7k0avuui7ksga"
          />
        )
      }
    </ContentBlockData>
  );
};

export default ContentBlockTitle;
