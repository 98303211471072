import { graphql } from 'gatsby'
import React from 'react'

import DetailsImageSection from './DetailsImageSection/details_image_section'
import FeatureDetails from './FeatureDetails/feature_details'
import FullBanner from './FullBanner/full_banner'
import FullWidthImage from './FullWidthImage/full_width_image'
import HalfBanner from './HalfBanner/half_banner'
import InstagramFeed from './InstagramSection/instagram_section'
import OverlappedImageGallery from './OverlappedImageGallery/overlapped_image_gallery'
import Review from './ReviewSection/review_section'
import ShopPanel from './ShopPanels/shop_panel_slice'
import SmallReviewBanner from './SmallReviewBanner/small_review_banner'
import SplitPanel from './SplitPanel/split_panel'
import StaticBody from './StaticBody/static_body'
import StaticHeader from './StaticHeader/static_header'
import UpsellProductTiles from './UpsellProductBanner/upsell_product_tiles'
import DisclamerImageBanner from './DisclamerImageBanner/disclamer_image_banner'
import LifetimeWarranty from './LifetimeWarranty/lifetime_warranty'
import DetailsSection from './DetailsProductSection/details_product_section'
import FourSquareImages from './FourSquareImage/four_square_image'
import SingleSkuShopExplore from './HoverShopExplore/hover-shop-explore-slice'
import TickerPanel from './TickerPanel/ticker-panel'
import AccordionBlock from './AccordionBlock/AccordionBlock'
import ButtonCTA from './Button/button_cta'
import SignUpPanel from './SignUpPanel/signup_panel'
import TwoAssetsBanner from './TwoAssetsBanner/two_assets_banner'
import MultipleImages from './MultipleImagesSection/multiple_images_section'
import SideImagesSectionHeritage from './SideImageSectionHeritage/side_images_section_heritage'
import MultipleSKUsComponent from './MultipleSKUsComponent/multipleSKUsComponent'
import MultipleSVGs from './MultipleSVG/multiple-SVGs'
import DetailedShopExplore from './DetailedShopExplore/detailed-shop-explore-slice'
import USPFeatureDetails from './USPFeatureDetails/usp_feature_details'
import CustomBannerRenderer from './customBanners'
import OkendoReferals from './okendoReferals'
import PaddedSplitPanel from './PaddedSplitPanel/padded_split_panel'
import PersonalisationPreview from '../panelsV2/PersonalisationPreview'
import FullBannerV2 from '../panelsV2/FullBanner'
import SplitPanelV2 from '../panelsV2/SplitPanel'
import SplitPanelV2Products from '../panelsV2/SplitPanel/SplitPanelProducts'
import RetailPanel from '../panelsV2/RetailPanel'
import CaroselPanel from '../panelsV2/Upsell/passthrough'
import PressPanel from '../panelsV2/ImageTextPanel/panel'
import TickerV2 from '../panelsV2/Ticker/ticker'
import FeatureDetailsV2 from '../panelsV2/FeatureDetails/feature_details'
import DisclaimerV2 from '../panelsV2/disclaimer/disclaimer'
import IndividualDiscountPanel from './IndividualDiscountPanel/hover-shop-explore-slice'
import DetailedImagePanel from '../panelsV2/DetailedImagePanel/detailed-image-panel'
import ProductTable from '../panelsV2/CompareTable/comparisionPage'
import OkendoQuiz from '../panelsV2/QuizPanel'
import CaroselSlice from '../panelsV2/Carosel/passthrough'
import ReviewPanel from '../panelsV2/Review/reviews'
import InstantCollectionTracking from '../tracking/InstantCollections'
import BundleBuilderPanel from './BundleBuilder/bundle_builder_panel_slice'
import ImageContentBlock from '../panelsV2/ImageContentBlock'
import DualImageBlock from '../panelsV2/DualImageBlock'
import ImageCarousel from '../panelsV2/ImageCarousel'
import SignUpTickerBlock from '../panelsV2/SignUpTickerBlock'
import ContentBlock from '../panelsV2/ContentBlock'
import AttentiveForm from '../panelsV2/AttentiveForm/attentive-form'
import ImageGallery from '../panelsV2/ImageGallery'
import HomepageTiles from '../panelsV2/HomepageTiles'
import ProductDetailTiles from '../panelsV2/ProductFeatureTiles'

const UniversalBlock = ({ data, locale }) => {
  const { universal_block: { Panels } } = data
  return <BlocksRenderer blocks={Panels} locale={locale} />
}

const componentsMap = {
  StrapiComponentPanelsUpsellProductTiles: UpsellProductTiles,
  StrapiComponentPanelsOverlappedImageGallery: OverlappedImageGallery,
  StrapiComponentPanelsFullBanner: FullBanner,
  StrapiComponentPanelsDetailsImageSection: DetailsImageSection,
  StrapiComponentPanelsHalfBanner: HalfBanner,
  StrapiComponentPanelsFullWidthImage: FullWidthImage,
  StrapiComponentPanelsSplitPanel: SplitPanel,
  StrapiComponentPanelsFeatureDetails: FeatureDetails,
  StrapiComponentPanelsReviewSection: Review,
  StrapiComponentPanelsStaticHeader: StaticHeader,
  StrapiComponentPanelsStaticBody: StaticBody,
  StrapiComponentPanelsInstagramSection: InstagramFeed,
  StrapiComponentPanelsSmallReviewBanner: SmallReviewBanner,
  StrapiComponentPanelsShopExplore: ShopPanel,
  StrapiComponentPanelsDisclamerBanner: DisclamerImageBanner,
  StrapiComponentPanelsFourSquareImage: FourSquareImages,
  StrapiComponentPanelsLifetimeWarranty: LifetimeWarranty,
  StrapiComponentPanelsProductDetailsBanner: DetailsSection,
  StrapiComponentPanelsSingleSkuShopExplore: SingleSkuShopExplore,
  StrapiComponentPanelsTicker: TickerPanel,
  StrapiComponentPanelsAccordionBlock: AccordionBlock,
  StrapiComponentPanelsButtonCta: ButtonCTA,
  StrapiComponentPanelsSignUpPanel: SignUpPanel,
  StrapiComponentPanelsTwoAssetsBanner: TwoAssetsBanner,
  StrapiComponentPanelsMultipleImages: MultipleImages,
  StrapiComponentPanelsSideImagesPanelHeritage: SideImagesSectionHeritage,
  StrapiComponentPanelsMultipleSkUsComponent: MultipleSKUsComponent,
  StrapiComponentPanelsMultipleSvGs: MultipleSVGs,
  StrapiComponentPanelsDetailedShopTile: DetailedShopExplore,
  StrapiComponentPanelsUspFeatureDetails: USPFeatureDetails,
  StrapiComponentPanelsCustomBanner: CustomBannerRenderer,
  StrapiComponentSharedUniversalBlock: UniversalBlock,
  StrapiComponentPanelsReferAFriend: OkendoReferals,
  StrapiComponentPanelsPaddedSplitPanel: PaddedSplitPanel,

  StrapiComponentPanelsV2PersonalisationPreview: PersonalisationPreview,
  StrapiComponentPanelsV2FullBannerV2: FullBannerV2,
  StrapiComponentPanelsV2SplitPanelV2: SplitPanelV2,
  StrapiComponentPanelsV2SplitPanelProducts: SplitPanelV2Products,
  StrapiComponentPanelsV2RetailPanel: RetailPanel,
  StrapiComponentPanelsV2ProductCarosel: CaroselPanel,
  StrapiComponentPanelsV2PressPanel: PressPanel,
  StrapiComponentPanelsV2TickerV2: TickerV2,
  StrapiComponentPanelsV2FeatureDetailsV2: FeatureDetailsV2,
  StrapiComponentPanelsV2DisclaimerV2: DisclaimerV2,
  StrapiComponentPanelsV2IndividualDiscountPanel: IndividualDiscountPanel,
  StrapiComponentPanelsV2DetailedImagePanel: DetailedImagePanel,
  StrapiComponentPanelsV2CompareTable: ProductTable,
  StrapiComponentPanelsV2OkendoQuiz: OkendoQuiz,
  StrapiComponentPanelsV2DiscountCarosel: CaroselSlice,
  StrapiComponentPanelsV2ReviewPanel: ReviewPanel,
  StrapiComponentPanelsV2TrackCollection: InstantCollectionTracking,
  StrapiComponentPanelsV2BundleBuilder: BundleBuilderPanel,
  StrapiComponentPanelsV2ContentBlock: ContentBlock,
  StrapiComponentPanelsV2ImageCarousel: ImageCarousel,
  StrapiComponentPanelsV2DualImageBlock: DualImageBlock,
  StrapiComponentPanelsV2ImageContent: ImageContentBlock,
  StrapiComponentPanelsV2TickerSignUp: SignUpTickerBlock,
  StrapiComponentPanelsV2AttentiveForm: AttentiveForm,
  StrapiComponentPanelsV2ImageGallery: ImageGallery,
  StrapiComponentPanelsV3HomepageTiles: HomepageTiles,
  StrapiComponentPanelsV3FeatureTiles: ProductDetailTiles
}

const Block = ({ block, locale, panelIndex }) => {
  const Component = componentsMap[block.__typename]
  if (!Component) {
    return null
  }

  return <Component data={block} locale={locale} panelIndex={panelIndex} />
}

const BlocksRenderer = ({ blocks, locale }) => {
  return (
    <div>
      {blocks.map((block, index) => (
        <Block key={index} block={block} locale={locale} panelIndex={index} />
      ))}
    </div>
  )
}

export const query = graphql`
  fragment Image on StrapiUploadFile {
    mime
    alternativeText
    name
    file {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH, breakpoints: [750, 1080, 1366, 1920])
        gatsbyImageDataMobile: gatsbyImageData(quality: 70, placeholder: NONE, breakpoints: [320, 480, 750], layout: FULL_WIDTH)
      }
      publicURL
    }
    url
  }

  fragment TileImages on StrapiUploadFile {
    mime
    alternativeText
    name
    file {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: CONSTRAINED, breakpoints: [750, 1080, 1366, 1920], width: 500)
        gatsbyImageDataMobile: gatsbyImageData(quality: 70, placeholder: NONE, breakpoints: [320, 480, 750], layout: CONSTRAINED, width: 400)
      }
      publicURL
    }
    url
  }

  fragment Panels on StrapiHomepagePanelsDynamicZone {
    __typename
    ... on StrapiComponentPanelsCustomBanner {
      bannername
    }
    ... on StrapiComponentPanelsV3HomepageTiles {
      Tiles {
        link
        LinkText
        Media {
          ...Image
        }
      }
    }
    ... on StrapiComponentPanelsDetailsImageSection {
      id
      backgroundColor
      textColor
      Heading
      DetailItems {
        DetailsText
        DetailImage {
          ...Image
        }
      }
    }
    ... on StrapiComponentPanelsProductDetailsBanner {
      id
      MainHeading
      SubHeading
      ImageHeight
      ProductDetailItems {
        Copy
        Heading
        TileImage {
          ...Image
        }
      }
      backgroundColor
    }
    ... on StrapiComponentPanelsReferAFriend {
      blank
    }
    ... on StrapiComponentPanelsSingleSkuShopExplore {
      Tiles {
        sku
        hoverImage {
          ...Image
        }
      }
      Button {
        backgroundColor
        ButtonCopy
        to
        textColor
        ActiveButtonColor
        HoverButtonColor
        BorderColor
        backgroundColor
        scrollTo
        hideOn
        ButtonPosition
        textColorHover
        textColorActive
      }
      discountPercentage
      ShopTile2x2
      ReverseHover
    }
    ... on StrapiComponentPanelsMultipleSkUsComponent {
      VariantWithImage {
        VariantTitle
        Image {
          ...Image
        }
      }
      Heading
    }
    ... on StrapiComponentPanelsLifetimeWarranty {
      id
      BannerImage {
        ...Image
      }
      Copy
      Heading
      SubCopy
    }
    ... on StrapiComponentPanelsV2TrackCollection {
      id
      CollectionName
    }
    ... on StrapiComponentPanelsFourSquareImage {
      id
      SIdeImage {
        ...Image
      }
      SquareImage1 {
        ...Image
      }
      SquareImage4 {
        ...Image
      }
      SquareImage3 {
        ...Image
      }
      SquareImage2 {
        ...Image
      }
    }
    ... on StrapiComponentPanelsDisclamerBanner {
      id
      BodyCopy
      Heading
      backgroundColor
      BodyFont
      BodyFontSize
      BodyFontWeight
      BodyColour
      SideImage {
        ...Image
      }
    }
    ... on StrapiComponentPanelsShopExplore {
      id
      showFilter
      header
      subHeader
      discountPercentage
      product_categories {
        CategoryName
      }
    }
    ... on StrapiComponentPanelsSmallReviewBanner {
      id
      ReviewID
      Copy
    }
    ... on StrapiComponentPanelsInstagramSection {
      id
      CTA
      Heading
      InstagramItems {
        Image {
          ...Image
        }
        FeedName
        UserName
      }
    }
    ... on StrapiComponentPanelsStaticBody {
      id
      Body
      Button {
        backgroundColor
        ButtonCopy
        to
        textColor
        ActiveButtonColor
        HoverButtonColor
        BorderColor
        backgroundColor
        scrollTo
        hideOn
        ButtonPosition
        textColorHover
        textColorActive
      }
    }
    ... on StrapiComponentPanelsStaticHeader {
      id
      HeaderCopy
      SubHeaderCopy
      backgroundColor
      textColor
      SubHeading
      SubHeaderFontSize
    }
    ... on StrapiComponentPanelsV2DiscountCarosel {
      Heading
      Subheading
      Products {
        DiscountAmount
        product {
          Title
          ShopPagePriority
          HideOnShopPage
          ShortName
          Discount
          ShopPageSubText
          product_categories {
            CategoryName
          }
          products {
            Variants {
              shopifyInfo {
                ... on UKShopifyProductVariant {
                  price
                }
                ... on USShopifyProductVariant {
                  price
                }
                ... on CAShopifyProductVariant {
                  price
                }
                ... on AUShopifyProductVariant {
                  price
                }
                ... on NZShopifyProductVariant {
                  price
                }
              }
              Colour
              ColourName
              SKU
              limited
              ShopTileImage {
                ...TileImages
              }
            }
            handle
          }
          BundleRange
          shopifyInfo {
            ... on UKShopifyProduct {
              shopifyId
              reviewData {
                aggregateRating
                shopifyId
                __typename
                reviewCount
              }
            }
            ... on NZShopifyProduct {
              shopifyId
              reviewData {
                aggregateRating
                shopifyId
                __typename
                reviewCount
              }

            }
            ... on USShopifyProduct {
              shopifyId
              reviewData {
                aggregateRating
                shopifyId
                __typename
                reviewCount
              }

            }
            ... on CAShopifyProduct {
              shopifyId
              reviewData {
                aggregateRating
                shopifyId
                __typename
                reviewCount
              }
            }
            ... on AUShopifyProduct {
              shopifyId
              reviewData {
                aggregateRating
                shopifyId
                __typename
                reviewCount
              }

            }
          }
          Variants {
            shopifyInfo {
              ... on UKShopifyProductVariant {
                price
              }
              ... on USShopifyProductVariant {
                price
              }
              ... on CAShopifyProductVariant {
                price
              }
              ... on AUShopifyProductVariant {
                price
              }
              ... on NZShopifyProductVariant {
                price
              }
            }
            Colour
            ColourName
            SKU
            limited
            ShopTileImage {
              ...TileImages
            }
          }
          handle
          locale
          Personalisation {
            PersonalisationCost
          }
          ShopPanelSpecs {
            Text
          }
          Highlight {
            Text
          }
        }
      }
    }
    ... on StrapiComponentPanelsV3FeatureTiles {
      Heading
      Tiles {
        Heading
        Body
        Image {
          ...Image
        }
      }
    }
    ... on StrapiComponentPanelsReviewSection {
      id
      ReviewItems {
        CustomerName
        ItemPurchased
        Rating
        ReviewDescription
        to
        ReviewImage {
          ...Image
        }
      }
    }
    ... on StrapiComponentPanelsFeatureDetails {
      id
      ShowSteps
      Title
      mobile2x2
      backgroundColor
      FeatureItems {
        FeatureImage {
          ...Image
        }
        Heading
        ItemStep
      }
      OptionalPNG {
        ...Image
      }
      TitleFont
      SubHeading
      IconSize
      extendedTitle
      TextColour
    }
    ... on StrapiComponentPanelsV2ImageGallery {
      GalImages {
        ...Image
      }
    }
    ... on StrapiComponentPanelsMultipleSvGs {
      Heading
      backgroundColor
      SVGitem {
        svg {
          ...Image
        }
      }
    }
    ... on StrapiComponentPanelsUpsellProductTiles {
      id
      HeadingText
      BrowseTheRange
      TextColor
      OverlayFont
      ProductTiles {
        TileImage {
          ...Image
        }
        TileSubHeading
        TileOverlayText
        TileTextDisplayOn
        TileOverlayTextOn
        to
        HoverText
        HoverTextOn
      }
      SubHeadingText
    }
    ... on StrapiComponentPanelsSplitPanel {
      id
      to
      BackgroundColour
      Body
      CTACopy
      DesktopImageOn
      Heading
      MaxWidth
      Image {
        ...Image
      }
      TitleImage {
        ...Image
      }
      ImagePadding
      MobileImageOn
      SubHeading
      TextAlignment
      TopBottomPadding
    }
    ... on StrapiComponentPanelsOverlappedImageGallery {
      id
      SmallImageSquare {
        ...Image
      }
      LargeImageHorizontal {
        ...Image
      }
      SmallImageVertical {
        ...Image
      }
    }
    ... on StrapiComponentPanelsTwoAssetsBanner {
      id
      to
      FirstAsset {
        ...Image
      }
      SecondAsset {
        ...Image
      }
      MobileFirstAsset {
        ...Image
      }
      MobileSecondAsset {
        ...Image
      }
    }
    ... on StrapiComponentPanelsMultipleImages {
      id
      Heading
      Font
      customHeading
      FontSize
      textColor
      FontWeight
      Images {
        Image {
          ...Image
        }
      }
      ImageText {
        Text
      }
    }
    ... on StrapiComponentPanelsHalfBanner {
      id
      BackgroundColor
      Body
      CTA
      to
      DImageOn
      aboveTheHeader
      HeightPercentage
      HeaderFont
      BodyFont
      HeaderFontWeight
      BodyFontWeight
      BodyFontSize
      HeaderFontStyle
      HeaderFontSize
      TextSectionPosition
      BodyAndCopyHide
      HideMediaOnMobile
      MobileHalfScreenImage {
        ...Image
      }
      TitleImage {
        ...Image
      }
      TextBackgroundImage {
        ...Image
      }
      HalfScreenImage {
        ...Image
      }
      Heading
      MImageOn
      TextColor
      Button {
        backgroundColor
        ButtonCopy
        to
        textColor
        ActiveButtonColor
        HoverButtonColor
        BorderColor
        backgroundColor
        scrollTo
        hideOn
        ButtonPosition
        textColorHover
        textColorActive
      }
      TextAlign
      MHeight
    }
    ... on StrapiComponentPanelsFullWidthImage {
      id
      height
      Image {
        ...Image
      }
      OverlayText
      OptionalMobileImage {
        mime
        alternativeText
        name
        file {
          childImageSharp {
            gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
            gatsbyImageDataMobile: gatsbyImageData(quality: 70, placeholder: NONE, layout: FULL_WIDTH)
            original {
              height
              width
            }
          }
          publicURL
        }
        url
      }
      MaxWidthImage
      linkTo
      MainFont
      MainFontSize
      MainFontWeight
      MainTextColor
      LazyLoading
    }
    ... on StrapiComponentPanelsAccordionBlock {
      Title
      BackgroundColor
      AccordionItem {
        AccordionTitle
        AccordionCopy
      }
    }
    ... on StrapiComponentPanelsButtonCta {
      ButtonCopy
      to
      textColor
      ActiveButtonColor
      HoverButtonColor
      backgroundColor
      BorderColor
      scrollTo
      HeaderButtonWithLogo
      Padding
      hideOn
      ButtonPosition
      textColorHover
        textColorActive
    }
    ... on StrapiComponentPanelsTicker {
      id
      tickerText
      subCopy
      extendedSubCopy
      textColor
      backgroundColour
      subCopyFontWeight
      subCopyFontSize
      Images {
        svg {
          ...Image
        }
      }
    }
    ... on StrapiComponentPanelsPaddedSplitPanel {
      Heading
      MediaFile {
        ...Image
      }
      Body
      DesktopPosLeft
      MobilePosTop
    }
    ... on StrapiComponentPanelsFullBanner {
      id
      Body
      to
      CTACopy
      HeightPercentage
      HeaderFont
      SubHeaderFont
      SubHeaderFontStyle
      HeaderFontWeight
      SubHeaderFontWeight
      HeaderFontSize
      HeaderTextColor
      SubHeaderTextColor
      SubHeaderFontSize
      LineHeight
      aboveTheHeader
      TitleImage {
        ...Image
      }
      TitleImageMobile {
        ...Image
      }
      TitleImageMobileHeight
      DesktopImage {
        ...Image
      }
      Heading
      MobileImage {
        ...Image
      }
      CustomHeading
      Button {
        backgroundColor
        ButtonCopy
        to
        textColor
        ActiveButtonColor
        HoverButtonColor
        BorderColor
        backgroundColor
        scrollTo
        hideOn
        ButtonPosition
        textColorHover
        textColorActive
        Padding
      }
      TextPosition
      TextPositionMobile
    }
    ... on StrapiComponentPanelsSignUpPanel {
      Body
      CTAcopy
      SignUpId
      Subheading
      Heading
      ConsentText
      includeName
      includePhoneNo
      Image {
        ...Image
      }
      Logo {
        ...Image
      }
    }
    ... on StrapiComponentPanelsSideImagesPanelHeritage {
      id
      HeaderFirst
      HeaderSecond
      roundImage
      textColor
      AssetFirst {
        ...Image
      }
      AssetSecond {
        ...Image
      }
      Font
      padding
    }
    ... on StrapiComponentPanelsDetailedShopTile {
      Header
      SubHeader
      Filters {
        FilterName
      }
      Items {
        sku
        FullDesc
        ShortDesc
        reviewId
      }
    }
    ... on StrapiComponentPanelsUspFeatureDetails {
      id
      Title
      Description
      BackgroundColor
      textColor
      AccordionItem {
        AccordionTitle
        AccordionCopy
      }
    }

    ... on StrapiComponentPanelsV2PersonalisationPreview {
      Theme {
        panel_theme {
          Background
          Text
        }
      }
      Subheading
      Personalisation {
        Colour
        Font
        Location
        Placeholder
        SKU
        Shadow
      }
      Heading
      CTA
      Body
      link
    }
    ... on StrapiComponentPanelsV2FullBannerV2 {
      id
      Body
      CTA
      Heading
      Media {
        Desktop {
          ...Image
        }
        Mobile {
          ...Image
        }
      }
      Height {
        Height
        strapiId
      }
      Theme {
        panel_theme {
          Background
          Text
        }
      }
      link
    }
    ... on StrapiComponentPanelsV2SplitPanelV2 {
      Body
      CTA
      Heading
      Image {
        ...Image
      }
      Theme {
        panel_theme {
          Background
          Text
        }
      }
      Subheading
      link
      overlapCompensation
      DesktopImagePosition
    }
    ... on StrapiComponentPanelsV2SplitPanelProducts {
      Body
      CTA
      Heading
      Image {
        ...Image
      }
      Products {
        CTA
        Image {
          ...TileImages
        }
        link
      }
      Subheading
      Theme {
        panel_theme {
          Background
          Text
        }
      }
      link
      overlapCompensation
    }
    ... on StrapiComponentPanelsV2RetailPanel {
      Locations {
        Details
        Image {
          ...Image
        }
        Name
      }
    }
    ... on StrapiComponentPanelsV2ProductCarosel {
      Subheading
      Heading
      Categories {
        Label
        products {
          CategoryName
        }
      }

      Tabs {
        sku
        LabelTag
        tiles {
          Title
        ShopPagePriority
        HideOnShopPage
        ShortName
        Discount
        ShopPageSubText
        product_categories {
          CategoryName
        }
        products {
          Variants {
            shopifyInfo {
              ... on UKShopifyProductVariant {
                price
              }
              ... on USShopifyProductVariant {
                price
              }
              ... on CAShopifyProductVariant {
                price
              }
              ... on AUShopifyProductVariant {
                price
              }
              ... on NZShopifyProductVariant {
                price
              }
            }
            Colour
            ColourName
            SKU
            limited
            Edition
            ShopTileImage {
              ...TileImages
            }
          }
          handle
        }
        BundleRange
        shopifyInfo {
          ... on UKShopifyProduct {
            shopifyId
            reviewData {
                aggregateRating
                shopifyId
                __typename
                reviewCount
              }

          }
          ... on NZShopifyProduct {
            shopifyId
            reviewData {
                aggregateRating
                shopifyId
                __typename
                reviewCount
              }

          }
          ... on USShopifyProduct {
            shopifyId
            reviewData {
                aggregateRating
                shopifyId
                __typename
                reviewCount
              }

          }
          ... on CAShopifyProduct {
            shopifyId
            reviewData {
                aggregateRating
                shopifyId
                __typename
                reviewCount
              }

          }

          ... on AUShopifyProduct {
            shopifyId
            reviewData {
                aggregateRating
                shopifyId
                __typename
                reviewCount
              }

          }
        }
        Variants {
          shopifyInfo {
            ... on UKShopifyProductVariant {
              price
            }
            ... on USShopifyProductVariant {
              price
            }
            ... on CAShopifyProductVariant {
              price
            }
            ... on AUShopifyProductVariant {
              price
            }
            ... on NZShopifyProductVariant {
              price
            }
          }
          Colour
          ColourName
          SKU
          limited
          Edition
          ShopTileImage {
            ...TileImages
          }
        }
        handle
        locale
        Personalisation {
          PersonalisationCost
        }
        ShopPanelSpecs {
          Text
        }
        Highlight {
          Text
        }

        }
      }
    }
    ... on StrapiComponentPanelsV2PressPanel {
      id
      Items {
        Logo {
          ...Image
        }
        Quote
      }
    }
    ... on StrapiComponentPanelsV2TickerV2 {
      id
      TickerText
      TickerSize
      BGcolor
      TickerFont
      TickerColor
    }
    ... on StrapiComponentPanelsV2FeatureDetailsV2 {
      Title
      TextFeatureItems {
        Header
        Description
        AdditionalPromo
      }
      BGcolor
      TextColor
    }
    ... on StrapiComponentPanelsV2DisclaimerV2 {
      Title
      Description
      Links
      Details
      BGcolor
      TextColor
      DisclaimerImage {
        ...Image
      }
    }
    ... on StrapiComponentPanelsV2IndividualDiscountPanel {
      Variant {
        sku
        discount
      }
    }

    ... on StrapiComponentPanelsV2DetailedImagePanel {
      Title
      Copy
      MainImage {
        ...Image
      }
      SideImage1 {
        ...Image
      }
      SideImage2 {
        ...Image
      }
      ColorSelectorLink {
        Link
        Color
      }
      FullWidthImage {
        id
        height
        Image {
          ...Image
        }
        OverlayText
        OptionalMobileImage {
          mime
          alternativeText
          name
          file {
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
              gatsbyImageDataMobile: gatsbyImageData(quality: 70, placeholder: NONE, layout: FULL_WIDTH)
              original {
                height
                width
              }
            }
            publicURL
          }
          url
        }
        MaxWidthImage
        linkTo
      }
    }
    ... on StrapiComponentPanelsV2CompareTable {
      TableColumnData {
        OptionalImage {
          ...Image
        }
        OptionalPrice
        OptionalTitle
        sku
        LinkTo
        OptionalTitleImage {
          ...Image
        }
        TableCell {
          Title
          Body
          Condition
        }
      }
      TableTitle
      TableToggle {
        ProductTitle
        to
        FeatureItems {
          FeatureDescription
          FeatureImage {
            ...Image
          }
        }
      }
    }
    ... on StrapiComponentPanelsV2OkendoQuiz {
      quizId
      subscriberId
      completedHeading
    }
    ... on StrapiComponentPanelsV2ReviewPanel {
      Title
      shopifyProductId
    }
    ... on StrapiComponentPanelsV2BundleBuilder {
      BundleBuilderStep {
        StepHeading
        StepSubHeading
        bgColour
        PostStepCopy
        ShopTileLabelText
        StepButtonCTA
        ShopTileButtonCTA
      }
    }

    ... on StrapiComponentPanelsV2ContentBlock {
      Title
      SubTitle
      Body
      PrimaryTextColor
      TitleSize
      TitleStyle
      SwapTitle
      BackgroundColor
      tolstoyWidgetOn
    }
    ... on StrapiComponentPanelsV2ImageCarousel {
      ImageCarousel {
        ...Image
      }
    }
    ... on StrapiComponentPanelsV2DualImageBlock {
      LeftImage {
        ...Image
      }
      RightImage {
        ...Image
      }
    }
    ... on StrapiComponentPanelsV2ImageContent {
      Title
      SubTitle
      Body
      ContentImages {
        mime
        alternativeText
        name
        file {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH, breakpoints: [750, 1080, 1366, 1920])
            gatsbyImageDataMobile: gatsbyImageData(quality: 80, placeholder: NONE, breakpoints: [320, 480, 750], layout: FULL_WIDTH)
          }
          publicURL
        }
        url
      }
      UnderImageText
      ImagePosLeft
      PrimaryColor
      SecondaryColor
      BackgroundColor
      BelowCopyImage {
        ...Image
      }
    }
    ... on StrapiComponentPanelsV2TickerSignUp {
      Title
      Body
      SignUpID
      TickerTapeCopy
    }

    ... on StrapiComponentPanelsV2AttentiveForm {
      landingPageUrl
    }
  }

  fragment RecursivePanels on StrapiHomepagePanelsDynamicZone {
    ...Panels
    ... on StrapiComponentSharedUniversalBlock {
      universal_block {
        Panels {
          ...Panels
        }
      }
    }
  }
`

export default BlocksRenderer
