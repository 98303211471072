import styled from '@emotion/styled'
import React from 'react'
import Link from '../../Link'
// Details
const TransparentButton = styled.button`
  font-family: Objectivity;
  border-radius: 27px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-decoration: underline;
  line-height: 49px;
  letter-spacing: 0.45px;
  text-align: center;
  color: ${(props) => props.textColor};
  height: 52px;
  padding: 0 48px;
  outline: none;
  cursor: pointer;
  min-width: 250px;
  z-index: 2;

  @media (max-width: 1024px) {
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    font-size: 14px;
    min-width: 250px;
    height: 50px;
  }
  @media (min-width: 1025px) {
    ${(props) => (props.noDesktop ? 'display: none;' : '')}
  }
  ${(props) => (props.center ? 'padding: 0 auto; min-width: 0;' : '')}

  transition: all 0.2s;

  &:hover {
    color: ${(props) => props.textColorHover ? props.textColorHover : props.backgroundColor};;
  }
  &:active {
    color: ${(props) => (props.textColorActive ? props.textColorActive : props.textColor)};
  }

  ${(props) => props.TextAlign === 'start' ? 'padding: 0; min-width: 0;' : ''};
`
const ButtonContainer = styled.div`
  display: flex;
  @media (min-width: 1025px) {
    ${(props) => (props.textPosition === 'custom-top' ? `justify-content: start;` : 'justify-content: center;')};
  }
`
const TopContainer = styled.div`  
  ${(props) => props.HeaderButtonWithLogo ? 'margin: 0 5%;' : ''};
  ${(props) => props.HeaderButtonWithLogo ? 'align-items: center;' : ''};
  
  justify-content: ${(props) => props.HeaderButtonWithLogo ? 'end;' : 'center;'}; 
  display: flex;

  @media (min-width: 1026px) {
    ${(props) => props.buttonPos === 'bottom_right' ? 'position: absolute; bottom: 0; right: 0; padding: 5% 7%;' : ''};
    ${(props) => (props.textPosition === 'custom-top' ? `position: absolute;bottom: 0;` : '')};  
  }
  @media (max-width: 1025px) {
    ${(props) => (props.hideButton === 'mobile' ? `display: none;` : '')};
    ${(props) => props.buttonPos === 'bottom_right' ? 'position: absolute; bottom: 0; right: 0; padding: 5%;' : ''};

    ${(props) => (props.hideCopyButton ? 'display: none;' : '')};
  }
  @media (max-width: 768px) {

    ${(props) => (props.hideButton === 'mobile' ? `display: none;` : '')};
    ${(props) => props.buttonPos === 'bottom_right' ? 'position: absolute; bottom: 0; padding: 5%;' : ''};

    ${(props) => (props.hideCopyButton ? 'display: none;' : '')};
  }
  ${(props) => props.TextAlign === 'start' ? 'justify-content: start; text-align: start; margin: 0;' : ''};
`
const handleScrollTo = () => {
  const heightSize = document.documentElement.scrollHeight
  window.scrollTo(0, heightSize - 4150)
}

const ButtonCTA = ({ data, textPosition, noLink, hideButton, TextAlign }) => {

  return (
    <TopContainer TextAlign={TextAlign} buttonPos={data?.ButtonPosition} textPosition={textPosition} HeaderButtonWithLogo={data?.HeaderButtonWithLogo} padding={data?.Padding} hideButton={data?.hideOn} hideCopyButton={hideButton}>
      {data?.scrollTo === 'bottom' ? (
        <Link noLink={true}>
          <ButtonContainer textPosition={textPosition}>
            <TransparentButton TextAlign={TextAlign} textPosition={textPosition} onClick={handleScrollTo} style={{ margin: '24px 0' }} textColor={data.textColor} ActiveButtonColor={data.ActiveButtonColor} HoverButtonColor={data.HoverButtonColor} backgroundColor={data?.backgroundColor} BorderColor={data.BorderColor} textColorHover={data?.textColorHover} textColorActive={data?.textColorActive}>
              {' '}
              {data.ButtonCopy}{' '}
            </TransparentButton>
          </ButtonContainer>{' '}
        </Link>
      ) : (
        <>
          <Link noLink={noLink} to={data.to}>
            <ButtonContainer textPosition={textPosition}>
              <TransparentButton TextAlign={TextAlign}  textPosition={textPosition} textColor={data.textColor} ActiveButtonColor={data.ActiveButtonColor} HoverButtonColor={data.HoverButtonColor} backgroundColor={data?.backgroundColor} BorderColor={data.BorderColor} textColorHover={data?.textColorHover} textColorActive={data?.textColorActive}>
                {' '}
                {data.ButtonCopy}{' '}
              </TransparentButton>
            </ButtonContainer>{' '}
          </Link>
        </>
      )
      }
    </TopContainer >
  )
}

export default ButtonCTA
