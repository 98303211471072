const auLookUp = {
  40: [
    'TPC01-BLA-BLU',
    'TPC02-BLA-RED',
    'TPC03-BLA-YEL',
    'TPC04-BLA-GRY',
  ], 
  30: [
    'EVE-CRES-81-NAT',
    'EVE-CRES-82-BLA',
    'EVE-CRES-84-YEL',
    'VANITY24-BLA',
  ], 
  20: [
    'EVE-BELT-94-YEL',
    'EVE-GARTOT51-NAT',
    'EVE-GARTOT52-BLA',
    'EVETOT-MINI61-NAT',
    'EVETOT-MINI62-BLA',
    'EVETOT-MINI63-YEL',
    'EVETOT-MINI65-GRY',
    'EVETOT2-LAR29-YEL',
    'EVETOT2-LAR33-LBLU',
    'JETTE-CRSBDY12-GRN',
    'JETTE-TOT-BKT31-BLA',
    'JETTE-TOT-BKT32-BRO',
    'JETTE-TOT-BKT34-CRM',
    'JETTE-TOT-SML07-BONE',
    'JETTE-TOT-SML08-GRN',
    'JETTE-TOT-XL54-CRM',
    'JETTE-TOT2-LRG14-CRM',
    'JETTE-TOT2-LRG15-LBLU',
    'JETTE-TOT2-SML21-BLA',
    'JETTE-TOT2-SML22-BRO',
    'JETTE-TOT2-SML23-CHBR',
    'JETTE-TOT2-SML24-CRM',
    'JETTE-TOT2-SML25-LBLU',
    'LGT-CO4-SAN',
    'LGT-CO7-CLA',
    'LGT-COEX24-SAN',
    'LGT-COEX25-CLA',
    'PRO-COPB07-CLA',
  ], 
  15: [
    'CHK04-NUD',
    'CHK07-CLY',
    'COES29-BLU',
    'COPB04-NUD',
    'COPB07-CLY',
    'EXP-CHK35-CLY',
    'EXP-CHK37-SAN',
    'EXP-CHK39-LTAU',
    'EXP-PLS45-CLY',
    'EXP-PLS47-SAN',
    'EXP-PLS49-LTAU',
    'PLS04-NUD',
    'PLS07-CLY',
    'PRO-COPB11-OLI',
    'PROV2-COPB24-SAN',
    'PROV2-COPB25-CLA',
    'TRK-CHK01-SAN',
    'TRK-CHK02-GRY',
    'TRK-CHK03-GRE',
    'TRK-CHK04-RED',
    'TRK-CHK06-CBLU',
    'TRK-COPB01-SAN',
    'TRK-COPB02-GRY',
    'TRK-COPB03-GRE',
    'TRK-COPB06-CBLU',
  ], 
  10: [
    'CME24-SAN',
    'CME25-CLA',
    'CME26-SHA',
    'CME30-MOS'
  ]
}
const usLookUp = {
  40: [
    'TPC01-BLA-BLU',
    'TPC02-BLA-RED',
    'TPC03-BLA-YEL',
    'TPC04-BLA-GRY',
  ], 
  30: [
    'EVETOT-MINI63-YEL',
    'EVETOT2-LAR29-YEL',
    'EVETOT2-LAR33-LBLU',
    'EVETOT2-SML40-YEL',
    'EVETOT2-SML44-LBLU',
  ], 
  20: [
    'CHK04-NUD',
    'CHK07-CLY',
    'EVE-BELT-91-NAT',
    'EVE-BELT-92-BLA',
    'EVE-BELT-93-BRO',
    'EVE-BELT-94-YEL',
    'EVE-CRES-81-NAT',
    'EVE-CRES-82-BLA',
    'EVE-CRES-83-BRO',
    'EVE-CRES-84-YEL',
    'EVE-GARTOT51-NAT',
    'EVE-GARTOT52-BLA',
    'EVETOT-MINI61-NAT',
    'EVETOT-MINI62-BLA',
    'EVETOT-MINI65-GRY',
    'JETTE-CRSBDY11-BONE',
    'JETTE-CRSBDY12-GRN',
    'JETTE-CRSBDY15-LBLU',
    'JETTE-TOT-SML05-BLA',
    'JETTE-TOT-SML07-BONE',
    'JETTE-TOT2-SML25-LBLU',
    'LGT-CO4-SAN',
    'LGT-COEX24-SAN',
    'LGT-COEX25-CLA',
    'PLS04-NUD',
    'PLS07-CLY',
    'PRO-COPB07-CLA',
  ], 
  15: [
    'COPB04-NUD',
    'COPB07-CLY',
    'EXP-CHK35-CLY',
    'EXP-CHK37-SAN',
    'EXP-PLS45-CLY',
    'EXP-PLS47-SAN',
    'EXP-PLS49-LTAU',
    'PROV2-COPB24-SAN',
    'PROV2-COPB25-CLA',
    'TRK-CHK01-SAN',
    'TRK-CHK02-GRY',
    'TRK-CHK03-GRE',
    'TRK-CHK04-RED',
    'TRK-COPB01-SAN',
    'TRK-COPB03-GRE',
    'TRK-COPB04-RED',
    'TRK-COPB06-CBLU',
  ], 
  10: [
    'CME24-SAN',
    'CME25-CLA',
    'CME30-MOS',
  ]
}

const nzLookUp = {
  40: [
    'TPC01-BLA-BLU',
    'TPC02-BLA-RED',
    'TPC03-BLA-YEL',
    'TPC04-BLA-GRY',
  ], 
  30: [
    'EVE-CRES-81-NAT',
    'EVE-CRES-82-BLA',
    'EVE-CRES-84-YEL',
    'VANITY24-BLA',
  ], 
  20: [
    'EVE-BELT-94-YEL',
    'EVE-GARTOT51-NAT',
    'EVE-GARTOT52-BLA',
    'EVETOT-MINI61-NAT',
    'EVETOT-MINI62-BLA',
    'EVETOT-MINI63-YEL',
    'EVETOT-MINI65-GRY',
    'EVETOT2-LAR29-YEL',
    'EVETOT2-LAR33-LBLU',
    'JETTE-CRSBDY12-GRN',
    'JETTE-TOT-BKT31-BLA',
    'JETTE-TOT-BKT32-BRO',
    'JETTE-TOT-BKT34-CRM',
    'JETTE-TOT-SML07-BONE',
    'JETTE-TOT-SML08-GRN',
    'JETTE-TOT-XL54-CRM',
    'JETTE-TOT2-LRG14-CRM',
    'JETTE-TOT2-LRG15-LBLU',
    'JETTE-TOT2-SML21-BLA',
    'JETTE-TOT2-SML22-BRO',
    'JETTE-TOT2-SML23-CHBR',
    'JETTE-TOT2-SML24-CRM',
    'JETTE-TOT2-SML25-LBLU',
    'LGT-CO4-SAN',
    'LGT-CO7-CLA',
    'LGT-COEX24-SAN',
    'LGT-COEX25-CLA',
    'PRO-COPB07-CLA',
  ], 
  15: [
    'CHK04-NUD',
    'CHK07-CLY',
    'COES29-BLU',
    'COPB04-NUD',
    'COPB07-CLY',
    'EXP-CHK35-CLY',
    'EXP-CHK37-SAN',
    'EXP-CHK39-LTAU',
    'EXP-PLS45-CLY',
    'EXP-PLS47-SAN',
    'EXP-PLS49-LTAU',
    'PLS04-NUD',
    'PLS07-CLY',
    'PRO-COPB11-OLI',
    'PROV2-COPB24-SAN',
    'PROV2-COPB25-CLA',
    'TRK-CHK01-SAN',
    'TRK-CHK02-GRY',
    'TRK-CHK03-GRE',
    'TRK-CHK04-RED',
    'TRK-CHK06-CBLU',
    'TRK-COPB01-SAN',
    'TRK-COPB02-GRY',
    'TRK-COPB03-GRE',
    'TRK-COPB06-CBLU',
  ], 
  10: [
    'CME24-SAN',
    'CME25-CLA',
    'CME26-SHA',
    'CME30-MOS'
  ]
}
const ukLookUp = {}
const caLookUp = {
  40: [
    'TPC01-BLA-BLU',
    'TPC02-BLA-RED',
    'TPC03-BLA-YEL',
    'TPC04-BLA-GRY',
  ], 
  30: [
    'EVETOT-MINI63-YEL',
    'EVETOT2-LAR29-YEL',
    'EVETOT2-LAR33-LBLU',
    'EVETOT2-SML40-YEL',
    'EVETOT2-SML44-LBLU',
  ], 
  20: [
    'CHK04-NUD',
    'CHK07-CLY',
    'EVE-BELT-91-NAT',
    'EVE-BELT-92-BLA',
    'EVE-BELT-93-BRO',
    'EVE-BELT-94-YEL',
    'EVE-CRES-81-NAT',
    'EVE-CRES-82-BLA',
    'EVE-CRES-83-BRO',
    'EVE-CRES-84-YEL',
    'EVE-GARTOT51-NAT',
    'EVE-GARTOT52-BLA',
    'EVETOT-MINI61-NAT',
    'EVETOT-MINI62-BLA',
    'EVETOT-MINI65-GRY',
    'JETTE-CRSBDY11-BONE',
    'JETTE-CRSBDY12-GRN',
    'JETTE-CRSBDY15-LBLU',
    'JETTE-TOT-SML05-BLA',
    'JETTE-TOT-SML07-BONE',
    'JETTE-TOT2-SML25-LBLU',
    'LGT-CO4-SAN',
    'LGT-COEX24-SAN',
    'LGT-COEX25-CLA',
    'PLS04-NUD',
    'PLS07-CLY',
    'PRO-COPB07-CLA',
  ], 
  15: [
    'COPB04-NUD',
    'COPB07-CLY',
    'EXP-CHK35-CLY',
    'EXP-CHK37-SAN',
    'EXP-PLS45-CLY',
    'EXP-PLS47-SAN',
    'EXP-PLS49-LTAU',
    'PROV2-COPB24-SAN',
    'PROV2-COPB25-CLA',
    'TRK-CHK01-SAN',
    'TRK-CHK02-GRY',
    'TRK-CHK03-GRE',
    'TRK-CHK04-RED',
    'TRK-COPB01-SAN',
    'TRK-COPB03-GRE',
    'TRK-COPB04-RED',
    'TRK-COPB06-CBLU',
  ], 
  10: [
    'CME24-SAN',
    'CME25-CLA',
    'CME30-MOS',
  ]
}

const skusDiscounts = (locale) => {
  return Object.entries(locale).reduce((skuData, [amount, SKUs]) => {
    SKUs.forEach(SKU => {
      skuData[SKU] = { type: 'percentage', amount: parseInt(amount) };
    });
    return skuData;
  }, {});
};

const AU = skusDiscounts(auLookUp);
const US = skusDiscounts(usLookUp);
const NZ = skusDiscounts(nzLookUp);
const UK = skusDiscounts(ukLookUp);
const CA = skusDiscounts(caLookUp);

export default { 'en-AU': AU, en: US, 'en-NZ': NZ, 'en-GB': UK, 'en-CA': CA }